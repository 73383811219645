import { authRoles } from 'app/auth';
import { Menu } from 'app/auth';

const navigationConfig = [{
    id: 'applications',
    title: 'Menu Principal',
    translate: 'MENU PRINCIPAL',
    type: 'group',
    icon: 'apps',
    children: Menu,
}];

export default navigationConfig;