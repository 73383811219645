import axios from 'axios';

class UserService {

    async recovery(data) {

        return await axios.post(process.env.REACT_APP_API_HOST + "/users/forgot-password", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

    async checkChangePwd(email) {
        return await axios.post(
            process.env.REACT_APP_API_HOST + "/users/check-change-pwd", {
            email
        }
        )
    }

    async changePassword(email, password) {
        return await axios.post(
            process.env.REACT_APP_API_HOST + "/users/change-password", {
            email,
            password,
        }
        )
    }

}

const instance = new UserService();

export default instance;