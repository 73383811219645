import { lazy } from 'react';

const SuscripcionesConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    auth: ['admin'],
    routes: [{
        path: '/suscripciones',
        component: lazy(() =>
            import ('./Suscripciones')),
    }, ],
};

export default SuscripcionesConfig;