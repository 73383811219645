import { lazy } from 'react';

const InvoiceConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    auth: ['suscriptor'],
    routes: [{
        path: '/invoice',
        component: lazy(() =>
            import ('./Invoice.js')),
    }, ],
};

export default InvoiceConfig;