import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
import DashboardConfig from 'app/main/dashboard/DashboardConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import { Redirect } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import zrouteConfig from 'app/main/zroute/zrouteConfig';
import InvoiceConfig from 'app/main/invoice/InvoiceConfig';
import SuscripcionesConfig from 'app/main/suscripciones/SuscripcionesConfig';
import { KudeConfig } from 'app/main/kude/KudeConfig';

const routeConfigs = [
    DashboardConfig,
    ...appsConfigs,
    LogoutConfig,
    LoginConfig,
    InvoiceConfig,
    KudeConfig,
    SuscripcionesConfig,
    LogoutConfig,
    zrouteConfig
];

const routes = [
    // if you want to make whole app auth protected by default change defaultAuth for example:
    // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
    // The individual route configs which has auth option won't be overridden.
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
    {
        path: '/loading',
        exact: true,
        component: () => < FuseLoading / > ,
    },
    {
        component: () => < Redirect to = "/zroute" / > ,
    },
];

export default routes;