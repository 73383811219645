import { lazy } from 'react';

const zrouteConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    auth: ['admin', 'suscriptor'],
    routes: [{
        path: '/zroute',
        component: lazy(() =>
            import ('./zroute')),
    }, ],
};

export default zrouteConfig;