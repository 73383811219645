import authRoles from "./authRoles";

const Menu = [
    {
        id: 'suscripciones',
        title: 'Suscripciones',
        type: 'item',
        auth: ['admin'],
        icon: 'group',
        url: '/suscripciones',
    },
    {
        id: 'invoice',
        title: 'Documentos',
        type: 'item',
        auth: ['suscriptor'],
        icon: 'dashboard',
        url: '/invoice',
    }
];

export default Menu;