import axios from 'axios';

class SuscripcionesService {

    async getSuscripciones(data) {

        return await axios.post(process.env.REACT_APP_API_HOST + "/suscripciones/getSuscripciones", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

    async add(data) {

        return await axios.post(process.env.REACT_APP_API_HOST + "/suscripciones/add", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

    async inhabilitar(data) {

        return await axios.post(process.env.REACT_APP_API_HOST + "/suscripciones/inhabilitar", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

    async habilitar(data) {

        return await axios.post(process.env.REACT_APP_API_HOST + "/suscripciones/habilitar", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

}

const instance = new SuscripcionesService();

export default instance;