import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Grid,
    Card,
    Button,
    TextField,
    CardContent,
    InputAdornment,
    IconButton,
    Icon,
    CircularProgress
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as yup from 'yup';
import _ from '@lodash';
import React, { useState, useEffect } from 'react'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import PropTypes from 'prop-types';
import { submitLogin } from 'app/auth/store/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import SuscripcionesService from 'app/services/SuscripcionesService';
import UserService from 'app/services/UserService';
import { useSnackbar } from 'notistack';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        background: (props) =>
            props.color === 'red'
                ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
                : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: (props) =>
            props.color === 'red'
                ? '0 3px 5px 2px rgba(255, 105, 135, .3)'
                : '0 3px 5px 2px rgba(33, 203, 243, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        margin: 8,
    }
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yup.string().required('Por favor ingrese el email'), //.email('Ingrese un email válido')
    password: yup
        .string()
        .required('Por favor ingrese la contraseña.')
    //.min(8, 'Password is too short - should be 8 chars minimum.'),
});

const defaultValues = {
    email: '',
    password: '',
    remember: true,
};

function LoginPage() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const login = useSelector(({ auth }) => auth.login);
    const [openRecover, setOpenRecover] = useState(false);
    const [openSignIn, setOpenSignIn] = useState(false);
    const [email, handleEmail] = useState();
    const [email_recovery, handleEmailRecovery] = useState();
    const [ruc_ci, handleRucCi] = useState();
    const [dv, handleDv] = useState();
    const [razon_social, handleRazonSocial] = useState();
    const [contraseña, handleContraseña] = useState();
    const [nro_factura, handleNroFactura] = useState();
    const [fecha_factura, handleFechaFactura] = useState(moment().format('YYYY-MM-DD'));
    const [celular, handleCelular] = useState();
    const [correo, handleCorreo] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    useEffect(() => {
        //console.log(login)
        login.errors.forEach((error) => {
            setError(error.type, {
                type: 'manual',
                message: error.message,
            });
        });
    }, [login.errors, setError]);

    function message(type, message) {
        enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 3000,
            preventDuplicate: true,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        })
    }

    function onSubmit(model) {
        dispatch(submitLogin(model));
    }

    function handleOpenSignIn() {
        setOpenSignIn(true);
    }

    function handleOpenRecover() {
        setOpenRecover(true);
    }

    function handleClose() {
        setOpenRecover(false);
        setOpenSignIn(false);
    }

    async function handleSaveRecover() {
        setLoading(true);

        if (email_recovery) {
            let data = {
                email: email_recovery
            };
            await UserService.recovery(data)
                .then(response => {
                    setLoading(false);
                    handleClose()
                    if (response.status == 200) {
                        if (response.data.status == "success") {
                            message("success", response.data.message);
                            handleEmailRecovery()
                        } else {
                            message("error", response.data.message);
                        }
                    } else {
                        message("error", response.data.message);
                    }

                })
                .catch(error => {
                    console.log(error)
                    message("error", "Ocurrio un error contácte con el administrador");
                });
        } else {
            setLoading(false);
            handleClose()
            message("error", "Debe ingresar un correo electrónico");
        }
    }

    function MyButton(props) {
        const { color, ...other } = props;
        const classes = useStyles(props);
        return <Button className={classes.root} {...other} />;
    }

    MyButton.propTypes = {
        color: PropTypes.oneOf(['blue', 'red']).isRequired,
    };

    async function handleGuardar() {
        setLoading(true);

        if (ruc_ci && razon_social && nro_factura && fecha_factura && correo && contraseña) {
            let data = {
                ruc_ci: ruc_ci,
                dv: dv,
                razon_social: razon_social,
                nro_factura: nro_factura,
                fecha_factura: fecha_factura,
                celular: celular,
                email: correo,
                password: contraseña
            };
            await SuscripcionesService.add(data)
                .then(response => {
                    setLoading(false);
                    handleClose()
                    if (response.status == 200) {
                        if (response.data.status == "success") {
                            message("success", response.data.message);
                            handleRucCi()
                            handleDv()
                            handleRazonSocial()
                            handleContraseña()
                            handleNroFactura()
                            handleFechaFactura()
                            handleCelular()
                            handleCorreo()
                        } else {
                            message("error", response.data.message);
                        }
                    } else {
                        message("error", response.data.message);
                    }

                })
                .catch(error => {
                    console.log(error)
                    message("error", "Ocurrio un error contácte con el administrador");
                });
        } else {
            handleClose()
            message("error", "Complete los campos obligatorios");
        }
    }

    return (
        <>
            <Dialog
                open={openRecover}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Cambio de contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Por favor, ingrese el correo electrónico con el cual se registro.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Dirección de correo"
                        value={email_recovery}
                        onChange={e => handleEmailRecovery(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSaveRecover} color="blue">

                        {loading ? <CircularProgress size={20} />
                            :
                            'Solicitar'
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openSignIn}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-tixs={20}tle">Registrarse</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus
                                id="email"
                                label="Ruc o CI"
                                value={ruc_ci}
                                onChange={e => handleRucCi(e.target.value)}
                                variant="outlined"
                                inputProps={{ maxLength: 8 }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="dv"
                                label="DV"
                                value={dv}
                                onChange={e => handleDv(e.target.value)}
                                variant="outlined"
                                inputProps={{ maxLength: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="dv"
                                label="Razon Social o Nombre completo"
                                value={razon_social}
                                onChange={e => handleRazonSocial(e.target.value)}
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="contraseña"
                                label="Contraseña"
                                type="password"
                                value={contraseña}
                                onChange={e => handleContraseña(e.target.value)}
                                variant="outlined"
                                required
                                InputProps={{
                                    className: 'pr-2',
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                <Icon className="text-20" color="action">
                                                    {showPassword ? 'visibility' : 'visibility_off'}
                                                </Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <br></br>
                        <Grid item xs={12} md={9}>
                            <Typography>Datos de la confirmación</Typography>
                        </Grid>
                        <br></br>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="nro_factura"
                                label="Número de la factura"
                                value={nro_factura}
                                onChange={e => handleNroFactura(e.target.value)}
                                variant="outlined"
                                inputProps={{ maxLength: 15 }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={esLocale}
                            >
                                <KeyboardDatePicker
                                    className="mb-16"
                                    value={fecha_factura}
                                    onChange={handleFechaFactura}
                                    label="Fecha de la factura"
                                    openTo="year"
                                    format="dd/MM/yyyy"
                                    views={["year", "month", "date"]}
                                    variant="outlined"
                                    required
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <br></br>
                        <Grid item xs={12} md={9}>
                            <Typography>Datos de la comunicación</Typography>
                        </Grid>
                        <br></br>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="celular"
                                label="Celular *"
                                value={celular}
                                onChange={e => handleCelular(e.target.value)}
                                variant="outlined"
                                inputProps={{ maxLength: 12 }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="correo"
                                label="Correo"
                                value={correo}
                                onChange={e => handleCorreo(e.target.value)}
                                variant="outlined"
                                required
                            />
                        </Grid>
                    </Grid>
                    <br></br>
                    <Typography>La información del RUC o CI será utilizada como usuario del sistema.</Typography>
                    <Typography>Los campos con * son obligatorios.</Typography>
                    <Typography>Los campos con ** son opcionales, pero se utilizaran para recuperar contraseña u otro tipo de comunicación con el cliente.</Typography>
                </DialogContent>
                <DialogActions>
                    <MyButton color="red" onClick={handleClose}>Cancelar</MyButton>
                    <MyButton color="blue" onClick={handleGuardar}>
                        {loading ? <CircularProgress size={20} />
                            :
                            'Guardar'
                        }
                    </MyButton>
                </DialogActions>
            </Dialog>

            <div
                className={clsx(
                    classes.root,
                    'flex flex-col flex-auto items-center justify-center p-16 sm:p-32'
                )}
            >
                <div className="flex flex-col items-center justify-center w-full">
                    <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                        <Card className="w-full max-w-384">
                            <CardContent className="flex flex-col items-center justify-center p-16 sm:p-24 md:p-32">
                                <img className="w-128 m-32" src="assets/images/logos/futura.png" alt="logo" />

                                <form
                                    name="loginForm"
                                    noValidate
                                    className="flex flex-col justify-center w-full"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="mb-16"
                                                type="text"
                                                error={!!errors.email}
                                                helperText={errors?.email?.message}
                                                label="Usuario"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Icon className="text-20" color="action">
                                                                account_circle
                                                            </Icon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="mb-16"
                                                label="Contraseña"
                                                type="password"
                                                error={!!errors.password}
                                                helperText={errors?.password?.message}
                                                variant="outlined"
                                                InputProps={{
                                                    className: 'pr-2',
                                                    type: showPassword ? 'text' : 'password',
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                                <Icon className="text-20" color="action">
                                                                    {showPassword ? 'visibility' : 'visibility_off'}
                                                                </Icon>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                required
                                            />
                                        )}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.root}
                                        aria-label="LOG IN"
                                        disabled={_.isEmpty(dirtyFields) || !isValid}
                                        type="submit"
                                    >
                                        Iniciar Sesión
                                    </Button>
                                </form>
                                <div className="flex flex-col items-center justify-center pt-32 pb-24">
                                    <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
                                        <span style={{ color: 'blue', marginTop: '10px', cursor: 'pointer' }} onClick={handleOpenRecover}>Olvidé mi contraseña</span>
                                    </div>
                                    <span className="font-normal">No tienes una cuenta?</span>
                                    <span style={{ color: 'blue', marginTop: '10px', cursor: 'pointer' }} onClick={handleOpenSignIn}>
                                        Registrarse
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                    </motion.div>
                </div>
            </div>
        </>

    );
}

export default LoginPage;
