import React from 'react';

export const KudeConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth : ['suscriptor'],
    routes  : [
        {
            path     : '/kude/:id?',
            component: React.lazy(() => import('./Kude'))
        }
    ]
};
